<template>
  <div class="container pointer" @click="toDetail">
    <img :src="info.coverUrl"/>
    <div class="title flex_row flex_align_center p_in_10">
      <div class="name">
      <div class="fs_18" style="color: rgba(255, 255, 255, 0.9);">{{info.title}}</div>
      <div class="fs_12 m_l_10" style="color: rgba(255, 255, 255, 0.8);">{{info.describes}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    data:()=>{
      return {
        
      }
    },
    props:{
      pageId:{
        type:String,
        default:''
      },
      cid:{
        type:String,
        default:''
      },
      info:{
       
      },
      from:{}
    },
    methods:{
      toDetail(){
        this.$router.push({path:'/party/exemplaryVanguard/partyDetail',query:{cid:this.info.id,id:this.pageId,listId:this.cid,from:this.from}});
      }
    }
  }
</script>

<style scoped lang="scss">
  .container{
    // width: 350px;
    // height:200px ;
    position: relative;
    height: 320px;
    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      border-radius: 6px;
    }
    .title{
      position: absolute;
      bottom: 0;
      border-radius: 6px;
      height: 130px;
      width: 100%;
      color: #fff;
      background-image: url("../../../assets/image/Mask groups.png");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100%;
      .name{    
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 40px;
        padding:25px 30px;
      }
    }
  }
  
  
</style>