<template>
  <div
    class="content pointer"
    @click="toDetail">
    <img :src="type == 2 ? info.coverUrl : info.url ? info.url : img" />
    <div class="title flex_row flex_align_center p_in_10">
      <div class="fs_18">{{ type == 2 ? info.title : info.deptName }}</div>
      <div class="fs_12 m_l_10">
        {{ type == 2 ? info.describes : info.title }}
      </div>
    </div>
  </div>
</template>

<script>
import img from '@/assets/image/Group 650.png';
export default {
  data: () => {
    return {
      img,
    };
  },
  props: {
    type: {
      type: Number,
      default: 1, //1学校党建2模范先锋
    },
    from: {
      type: Number,
      default: 1, //1先进党组织2先进党组织列表
    },
    pageId: {
      type: String,
      default: '',
    },
    cid: {
      type: String,
      default: '',
    },
    info: {},
  },
  methods: {
    toDetail() {
      this.$router.push({
        path:
          this.type == 1
            ? '/party/schoolParty/detail'
            : '/party/exemplaryVanguard/schoolDetail',
        query: {
          cid: this.info.id,
          from: this.from,
          id: this.pageId,
          listId: this.cid,
        },
      });
    },
  },
};
</script>

<style
  scoped
  lang="scss">
.content {
  position: relative;
  height: 213px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  .title {
    position: absolute;
    bottom: 0;
    border-radius: 6px;
    width: 100%;
    color: #fff;
    background: linear-gradient(90deg, #dd2622 0%, rgba(221, 38, 34, 0) 100%);
  }
}
</style>
