<template>
  <div class="container" v-loading="loading">
    <div class="row">
      <Title title="先进党组织" :path="'/party/exemplaryVanguard/schoolList?id='+$route.query.id+'&cid='+ddzId"></Title>
      <div class="xjdzz">
        <div class="list" v-for="item in xxdzz" :key="item.id">
          <SchoolList :type='2' :info="item" :from="1" :pageId="$route.query.id"></SchoolList>
        </div>
        <div class="list" v-show="xxdzz.length%3!=0" v-for="(i,index) in 3-xxdzz.length%3" :key="index"></div>
      </div>
    </div>
    <div class="row">
      <Title title="优秀党员" :path="'/party/exemplaryVanguard/partyList?id='+$route.query.id+'&cid='+yxdyId"></Title>
      <div class="yxdy">
        <div class="list" v-for="item in yxdy" :key="item.id">
          <PartyMemberList :info="item" :pageId="$route.query.id" :from="3"></PartyMemberList>
        </div>
        <div class="list" v-show="yxdy.length%4!=0" v-for="(i,index) in 4-yxdy.length%4" :key="index"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getContents,
    getPartyResources
  } from '@/api/party/index'
  import SchoolList from "../components/SchoolList.vue"
  import PartyMemberList from "../components/PartyMemberList.vue"
  import Title from "../components/Title.vue"
  export default {
    name: 'index',
    data: () => {
      return {
        loading: false,
        parameter: {
          contentIds: '',
          current: 1,
          size: 7,
          isExamine: 1
        },
        ddzId: '',
        yxdyId: '',
        xxdzz: [],
        yxdy: [],

      }
    },
    components: {
      SchoolList,
      Title,
      PartyMemberList
    },
    created() {
      this.loading = true;
      /* 模范先锋*/
      getContents({
        id: this.$route.query.id
      }).then(res => {
        //先进党组织
        this.parameter.contentIds = res.data[0].children[0].id;
        this.ddzId = res.data[0].children[0].id;
        getPartyResources(this.parameter).then(res => {
          this.xxdzz = res.data.records;
        })
        //优秀党员
        this.parameter.contentIds = res.data[0].children[1].id;
        this.yxdyId = res.data[0].children[1].id;
        getPartyResources(this.parameter).then(res => {
          this.yxdy = res.data.records;
        })
        this.loading = false;
      })
    },
    methods: {
      //三会一课详情界面
      getMore() {
        this.$router.push('/party/politicalLife/detail?id=' + this.id);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .row:nth-child(1) {
      margin-top: 25px;
    }

    .row {
      >div {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .xjdzz {
        .list {
          width: 32.8%;
          margin-bottom: 10px;
        }
      }

      .yxdy {
        .list {
          width: 24.3%;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
